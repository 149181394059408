@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

/*
  This needs to be a css class because we can't add after:content-[] with tailwind dynamically, because it get's tree-shaken out.
  When adding other after: classes, tailwind adds content: '' by default, so we need to override it.
*/
.input-w-suffix::after {
  content: attr(data-suffix) !important;
}
